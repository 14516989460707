import { ChainId } from "entities/chain"
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb', // TODO
  [ChainId.BSCTESTNET]: '0x8Ff2a81a54Bb31E6417FeeF9E0bd00F10Dd0a794',

}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
