/* eslint-disable */

import invariant from 'tiny-invariant'
import { ChainId } from './chain'
import { Currency } from './currency'
import { getAddress } from '@ethersproject/address'
import warning from 'tiny-warning'
// import { Currency } from 'entities/export'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */

export function validateAndParseAddress(address: string): string {
    try {
        const checksummedAddress = getAddress(address)
        warning(address === checksummedAddress, `${address} is not checksummed.`)
        return checksummedAddress
    } catch (error) {
        invariant(false, `${address} is not a valid address.`)
    }
}

export class Token extends Currency {
    public readonly chainId: ChainId
    public readonly address: string

    public constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string) {
        super(decimals, symbol, name)
        this.chainId = chainId
        this.address = validateAndParseAddress(address)
    }



    /**
     * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
     * @param other other token to compare
     */
    public equals(other: Token): boolean {
        // short circuit on reference equality
        if (this === other) {
            return true
        }
        return this.chainId === other.chainId && this.address === other.address
    }

    /**
     * Returns true if the address of this token sorts before the address of the other token
     * @param other other token to compare
     * @throws if the tokens have the same address
     * @throws if the tokens are on different chains
     */
    public sortsBefore(other: Token): boolean {
        invariant(this.chainId === other.chainId, 'CHAIN_IDS')
        invariant(this.address !== other.address, 'ADDRESSES')
        return this.address.toLowerCase() < other.address.toLowerCase()
    }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
    if (currencyA instanceof Token && currencyB instanceof Token) {
        return currencyA.equals(currencyB)
    } else if (currencyA instanceof Token) {
        return false
    } else if (currencyB instanceof Token) {
        return false
    } else {
        return currencyA === currencyB
    }
}

export const WETH = {
    [ChainId.MAINNET]: new Token(
        ChainId.MAINNET,
        '0x4200000000000000000000000000000000000006',
        18,
        'WBNB',
        'Wrapped BNB'
    ),
    [ChainId.BSCTESTNET]: new Token(
        ChainId.BSCTESTNET,
        '0x4200000000000000000000000000000000000006',
        18,
        'WBNB',
        'Wrapped BNB'
    ),

}

