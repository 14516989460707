import React from 'react'
import "./ComingSoon.css"

function ComingSoon() {
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ color: '#FEBF32', marginTop: '20px', fontSize: '30px', fontWeight: 'bolder' }}>Coming Soon!</div>
      <img src="images/coming-soon.jpg" alt="" className="Coming-img" />
    </div>
  )
}

export default ComingSoon
