
import JSBI from 'jsbi';

export type BigintIsh = JSBI | bigint | string

export enum TradeType {
    EXACT_INPUT,
    EXACT_OUTPUT
}

export enum Rounding {
    ROUND_DOWN,
    ROUND_HALF_UP,
    ROUND_UP
}

export const INIT_CODE_HASH = '0xd69c0284b830fdfdaf4a8f54984585243053bc8d3d68d8b06040676576bd816e'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _998 = JSBI.BigInt(998)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
    uint8 = 'uint8',
    uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
    [SolidityType.uint8]: JSBI.BigInt('0xff'),
    [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
// SokuSwap Router
// export const ROUTER_ADDRESS = '0xcFfbb493d9995CC80D23EE4fE06DE7e385a4C1B6'

// PancakeSwap Router
export const ROUTER_ADDRESS = '0xfB3389cc8946B82a0Ed3b6527dF9507FF38dfC15'

// Pancake Factory
export const FACTORY_ADDRESS = '0xf896e75B4D5Be5De7a26C8110f291757027d8021'
export const INIT_CODE = '0xd69c0284b830fdfdaf4a8f54984585243053bc8d3d68d8b06040676576bd816e'



export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 80
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states


// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
