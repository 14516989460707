/**
 * A currency is any fungible financial instrument on Ethereum, including Ether and all ERC20 tokens.
 *
 * The only instance of the base class `Currency` is Ether.
 */
import JSBI from "jsbi"
import { validateSolidityTypeInstance } from "./utils"

import { SolidityType } from "./constants";


export class Currency {
    readonly decimals: number;

    readonly symbol?: string;

    readonly name?: string;
    /**
     * The only instance of the base class `Currency`.
     */

    static readonly ETHER = new Currency(18, "BNB", "Binance");

    /**
     * Constructs an instance of the base class `Currency`. The only instance of the base class `Currency` is `Currency.ETHER`.
     * @param decimals decimals of the currency
     * @param symbol symbol of the currency
     * @param name of the currency
     */
    constructor(decimals: number, symbol?: string, name?: string) {
        validateSolidityTypeInstance(JSBI.BigInt(decimals), SolidityType.uint8);
        this.decimals = decimals;
        this.symbol = symbol;
        this.name = name;
    }
}
export const {ETHER} = Currency;

