import { Rounding, _100, BigintIsh, ONE } from '../constants'
import { Fraction } from './fraction'

/* eslint-disable */

const _100_PERCENT = new Fraction(_100)

export class Percent extends Fraction {


    // public constructor(numerator: BigintIsh, denominator: BigintIsh = ONE) {
    //     super(numerator, denominator)
    // }

    public toSignificant(significantDigits: number = 5, format?: object, rounding?: Rounding): string {
        return this.multiply(_100_PERCENT).toSignificant(significantDigits, format, rounding)
    }

    public toFixed(decimalPlaces: number = 2, format?: object, rounding?: Rounding): string {
        return this.multiply(_100_PERCENT).toFixed(decimalPlaces, format, rounding)
    }
}

 